import React from 'react';

const Logo = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 1550 250"
            enableBackground="new 0 0 1550 250"
            xmlSpace="preserve"
        >
            <path
                fill="#FEFFFF"
                opacity="1.000000"
                stroke="none"
                d="M1143.000000,251.000000 C762.000000,251.000000 381.500031,251.000000 1.000051,251.000000 
	C1.000034,167.666687 1.000034,84.333389 1.000017,1.000062 
	C517.666626,1.000041 1034.333252,1.000041 1551.000000,1.000021 
	C1551.000000,84.333275 1551.000000,167.666550 1551.000000,250.999908 
	C1415.166626,251.000000 1279.333374,251.000000 1143.000000,251.000000 
M1156.212891,114.750069 
	C1156.212891,114.750069 1156.368896,114.692757 1156.097534,114.119110 
	C1155.083008,113.046471 1154.068481,111.973839 1152.932739,110.105148 
	C1150.105225,99.762619 1153.414307,94.489967 1163.853516,92.740349 
	C1165.492432,92.465668 1167.144165,92.101181 1168.793701,92.081139 
	C1197.100220,91.737152 1225.406982,91.401787 1253.714478,91.152222 
	C1265.991943,91.043983 1278.271362,91.134056 1290.661377,91.134056 
	C1290.661377,79.781807 1290.661377,68.906532 1290.661377,57.913006 
	C1253.837402,57.913006 1217.370239,57.529556 1180.917847,58.095955 
	C1165.683594,58.332672 1150.322510,59.645447 1135.304443,62.167675 
	C1117.330444,65.186325 1108.377930,74.717255 1106.427124,92.353462 
	C1105.271484,102.800247 1105.600586,113.644516 1106.979370,124.083199 
	C1108.997192,139.357239 1118.192261,147.899094 1133.313843,150.715637 
	C1137.063477,151.414062 1140.894165,151.971832 1144.697510,152.040436 
	C1167.673462,152.454819 1190.653320,152.650162 1213.629761,153.046097 
	C1222.113281,153.192291 1230.604980,153.487228 1239.066162,154.090118 
	C1244.419922,154.471634 1249.133667,156.295120 1251.004639,162.213303 
	C1254.023804,171.763550 1249.906372,178.836502 1239.408325,179.756470 
	C1225.174194,181.003845 1210.849121,181.666779 1196.559326,181.728043 
	C1168.750610,181.847321 1140.939331,181.370346 1113.128906,181.157257 
	C1111.875977,181.147644 1110.621704,181.304321 1109.442627,181.378830 
	C1109.442627,192.523026 1109.442627,203.271301 1109.442627,214.855606 
	C1113.691895,214.855606 1117.652954,214.864578 1121.614014,214.854202 
	C1155.760498,214.764786 1189.912231,214.995407 1224.050781,214.426788 
	C1238.480225,214.186462 1252.961792,212.856644 1267.272705,210.930756 
	C1280.794067,209.111130 1291.368408,202.383972 1295.593628,188.373535 
	C1299.927734,174.002014 1299.904541,159.493179 1296.450806,144.975616 
	C1293.153687,131.116898 1283.979980,123.263947 1270.256714,120.914139 
	C1263.254150,119.715111 1256.062866,119.618156 1248.254028,118.833389 
	C1246.829956,118.812340 1245.405762,118.791283 1243.245972,118.510284 
	C1242.466309,118.507301 1241.686646,118.504311 1240.083130,118.192482 
	C1237.743286,118.246292 1235.403442,118.300102 1232.390747,118.176567 
	C1231.928467,118.185738 1231.466187,118.194908 1230.085693,117.987953 
	C1209.157593,117.901276 1188.229614,117.814598 1166.532227,117.351822 
	C1165.681274,117.300720 1164.830444,117.249611 1163.272339,116.984436 
	C1161.855469,116.654640 1160.438599,116.324837 1159.004150,115.987503 
	C1159.004150,115.987503 1158.985107,115.984680 1158.515625,115.579071 
	C1157.763062,115.249390 1157.010376,114.919716 1156.212891,114.750069 
M1036.056763,211.772873 
	C1038.676025,212.877625 1041.245728,214.766251 1043.924072,214.935165 
	C1051.005249,215.381744 1058.144287,215.305817 1065.236816,214.957840 
	C1067.635132,214.840179 1069.962891,213.283524 1072.275879,212.286560 
	C1072.275879,212.286560 1072.379761,212.318420 1072.975830,211.903214 
	C1076.070068,200.902740 1075.140991,189.829224 1073.533325,178.751785 
	C1073.473389,178.338806 1072.928711,177.996201 1072.504883,177.501450 
	C1072.504883,177.501450 1072.396240,177.385391 1071.968384,176.811691 
	C1070.297241,176.179825 1068.631592,175.022461 1066.953857,175.004364 
	C1058.307007,174.911057 1049.656860,175.109924 1040.441772,175.199341 
	C1039.954590,175.448395 1039.467285,175.697449 1038.371460,175.982391 
	C1037.782959,176.507172 1037.194580,177.031967 1036.602905,177.640350 
	C1036.602905,177.640350 1036.519409,177.646225 1035.959961,178.102386 
	C1035.427002,179.639984 1034.459961,181.170166 1034.433594,182.716431 
	C1034.294922,190.831116 1034.325928,198.951172 1034.478760,207.065964 
	C1034.504028,208.416443 1035.421021,209.750107 1035.990479,211.008316 
	C1035.990479,211.008316 1035.902588,211.066299 1036.056763,211.772873 
M933.360046,59.999088 
	C933.166199,60.299870 932.972412,60.600647 932.198425,61.207863 
	C903.721680,89.196022 875.244873,117.184174 846.623657,145.314316 
	C843.927917,142.794067 841.448059,140.580704 839.084229,138.249603 
	C813.348450,112.869797 787.677002,87.424255 761.812012,62.177002 
	C759.935852,60.345623 756.701782,59.022785 754.076294,58.983463 
	C735.753845,58.709034 717.425354,58.829006 699.098816,58.845356 
	C697.521973,58.846764 695.945251,59.012684 694.626343,59.087547 
	C694.626343,110.947723 694.626343,162.184998 694.626343,213.639221 
	C708.714111,213.639221 722.308167,213.639221 736.582642,213.639221 
	C736.582642,176.103516 736.582642,138.773499 736.582642,100.229088 
	C773.760803,135.834351 809.975830,170.517197 846.501221,205.497284 
	C871.222412,181.845947 895.392212,158.704788 919.583008,135.585648 
	C931.727417,123.979263 943.910156,112.412941 956.210571,101.781509 
	C956.210571,139.079071 956.210571,176.376648 956.210571,213.680634 
	C968.833252,213.680634 980.447327,213.680634 992.682434,213.815674 
	C993.101379,213.771133 993.520264,213.726593 994.713989,213.898956 
	C996.040894,213.246216 997.367798,212.593475 998.827271,211.020264 
	C998.837219,183.527328 998.872986,156.034363 998.849060,128.541443 
	C998.829163,105.717682 998.739136,82.893974 998.633301,59.313591 
	C997.853577,59.099789 997.074219,58.700874 996.294067,58.699257 
	C976.649231,58.658443 957.004211,58.638775 937.359558,58.704521 
	C936.187805,58.708447 935.018616,59.455509 933.965759,59.971954 
	C933.965759,59.971954 933.882874,59.829792 933.360046,59.999088 
M515.304626,72.588203 
	C500.200653,87.649742 485.041351,102.656891 470.083801,117.862480 
	C468.687744,119.281670 467.958313,121.953476 467.947876,124.048820 
	C467.803497,153.046005 467.835724,182.044083 467.841492,211.041946 
	C467.841766,212.452728 467.996521,213.863480 468.161011,216.664948 
	C500.640900,184.119217 532.254089,152.441956 564.483093,121.094536 
	C596.167542,152.783447 627.851929,184.472336 660.176453,216.801437 
	C660.421082,215.022247 660.581177,214.400513 660.581421,213.778717 
	C660.592346,183.649216 660.635437,153.519485 660.477539,123.390816 
	C660.467773,121.523979 659.294373,119.241722 657.937988,117.868080 
	C642.967468,102.707390 627.853455,87.688461 612.523132,72.112732 
	C596.407776,55.960407 580.292480,39.808086 563.950684,23.428755 
	C553.729309,33.692963 544.096985,43.307701 534.533264,52.990192 
	C528.244690,59.356804 522.055115,65.821060 515.304626,72.588203 
M138.076538,118.009239 
	C117.459953,117.925179 96.843376,117.841110 75.411232,117.471451 
	C74.265877,117.384758 73.120522,117.298065 71.261940,116.998428 
	C70.519463,116.981689 69.776978,116.964943 68.539154,116.464661 
	C61.227894,113.375969 59.085411,109.991867 59.852200,102.743385 
	C60.507362,96.550079 63.365810,93.694084 71.502632,92.826363 
	C80.420723,91.875328 89.417603,91.287781 98.384232,91.235001 
	C129.868500,91.049652 161.354523,91.162369 192.839890,91.161934 
	C194.604691,91.161911 196.369476,91.161926 198.289642,91.161926 
	C198.289642,79.752823 198.289642,68.995834 198.289642,58.113270 
	C197.031525,58.005177 196.053116,57.847904 195.074661,57.847626 
	C160.104828,57.837734 125.128021,57.433578 90.168343,58.030533 
	C74.433418,58.299213 58.621937,59.826984 43.048393,62.147301 
	C28.230722,64.354996 17.729557,72.713165 14.648162,88.341888 
	C12.172525,100.898232 12.145205,113.454056 14.768839,126.005135 
	C17.564571,139.379486 25.945042,147.427734 39.030636,150.066925 
	C47.137463,151.701950 55.506287,152.565552 63.779568,152.742249 
	C83.429611,153.161926 103.095650,152.786179 122.749374,153.099319 
	C132.034058,153.247253 141.335617,153.958832 150.570923,154.957199 
	C155.840088,155.526810 158.426208,159.392380 159.132294,164.568771 
	C160.411682,173.947983 156.821167,179.229065 146.663818,179.811935 
	C128.250671,180.868561 109.783134,181.210342 91.334404,181.355286 
	C67.849174,181.539810 44.360104,181.228668 20.872633,181.146362 
	C19.603737,181.141922 18.334351,181.277359 17.080664,181.346832 
	C17.080664,192.485458 17.080664,203.263214 17.080664,214.855835 
	C21.601063,214.855835 25.891796,214.849136 30.182505,214.856934 
	C55.337814,214.902679 80.500877,215.357361 105.646042,214.875870 
	C127.089592,214.465240 148.575394,213.681320 169.926727,211.769485 
	C190.750626,209.904892 201.377121,200.295547 204.850830,182.716248 
	C207.166321,170.998260 207.119431,159.200348 204.806717,147.512558 
	C202.162918,134.151520 194.676666,124.814240 181.005798,121.572784 
	C175.770416,120.331436 170.328156,119.962601 164.086578,118.989662 
	C159.387344,118.915352 154.688095,118.841042 149.353302,118.457367 
	C148.886505,118.444626 148.419693,118.431885 147.336014,118.169739 
	C146.902359,118.232254 146.468704,118.294769 145.167465,118.188393 
	C143.107010,118.209412 141.046539,118.230431 138.076538,118.009239 
M321.500000,213.857773 
	C359.443146,213.857773 397.386261,213.857773 435.533569,213.857773 
	C435.533569,200.707764 435.533569,188.154922 435.533569,175.140488 
	C427.416626,175.140488 419.619690,175.150177 411.822784,175.138824 
	C377.327118,175.088577 342.830841,175.131470 308.335999,174.933167 
	C292.867920,174.844254 285.172760,169.447861 283.694489,155.179001 
	C282.299469,141.713821 282.481171,127.911041 283.667847,114.406013 
	C284.675873,102.934212 290.471405,98.455521 301.934509,96.704178 
	C304.882568,96.253777 307.897186,96.063431 310.881958,96.056198 
	C350.544556,95.960045 390.207306,95.917747 429.869995,95.862556 
	C431.780792,95.859894 433.691559,95.862206 435.540894,95.862206 
	C435.540894,83.074745 435.540894,71.149467 435.540894,58.835648 
	C433.734253,58.835648 432.260651,58.835625 430.787048,58.835651 
	C386.289856,58.836441 341.792542,58.789055 297.295563,58.886986 
	C290.147583,58.902718 282.981750,59.347569 275.857544,59.973515 
	C255.146179,61.793270 240.158188,75.472588 237.040115,96.001991 
	C233.293625,120.668922 233.472946,145.521896 236.374023,170.208832 
	C239.466736,196.526535 252.491241,209.117905 279.121643,211.990921 
	C292.822113,213.468979 306.701477,213.288681 321.500000,213.857773 
M1324.603882,101.113480 
	C1322.049438,125.548836 1321.791992,150.011276 1325.369995,174.328812 
	C1328.182617,193.445587 1338.888184,206.668152 1358.277100,210.457413 
	C1370.118164,212.771576 1382.398438,213.599503 1394.494263,213.711411 
	C1435.641113,214.092102 1476.793823,213.858109 1517.944214,213.858810 
	C1519.550293,213.858841 1521.156250,213.858795 1522.817505,213.858795 
	C1522.817505,200.656143 1522.817505,188.097672 1522.817505,175.140518 
	C1520.836060,175.140518 1519.202148,175.140381 1517.568237,175.140533 
	C1498.409058,175.142303 1479.250000,175.166733 1460.090820,175.139862 
	C1438.934082,175.110184 1417.775757,175.165787 1396.620972,174.933929 
	C1380.715454,174.759598 1373.839966,169.239334 1371.803467,153.619766 
	C1370.521484,143.787445 1370.750244,133.702682 1370.949951,123.744186 
	C1371.370117,102.803520 1378.247681,96.143929 1399.304199,96.059258 
	C1437.953491,95.903839 1476.603516,95.918091 1515.253174,95.862167 
	C1517.184937,95.859367 1519.116821,95.861801 1521.153809,95.861801 
	C1521.153809,83.179985 1521.153809,71.156509 1521.153809,58.836266 
	C1518.912598,58.836266 1517.093140,58.836254 1515.273682,58.836269 
	C1472.790405,58.836632 1430.307129,58.786957 1387.824097,58.883492 
	C1380.179443,58.900860 1372.515625,59.274502 1364.895996,59.908108 
	C1342.183838,61.796749 1326.844116,77.328094 1324.603882,101.113480 
M1463.574585,155.928986 
	C1464.734375,157.344635 1466.071533,158.651306 1467.020386,160.196442 
	C1469.823120,164.760071 1473.635986,166.189346 1479.061035,166.010590 
	C1492.359741,165.572372 1505.682617,165.877655 1518.995850,165.853043 
	C1520.192993,165.850830 1521.389648,165.625763 1523.228516,165.438980 
	C1514.900635,155.182907 1507.116089,145.454483 1499.104370,135.916809 
	C1498.154297,134.785751 1495.968872,134.230057 1494.343506,134.204575 
	C1484.527466,134.050781 1474.707764,134.129150 1464.889282,134.129593 
	C1458.839478,134.129852 1452.789551,134.129623 1445.664795,134.129623 
	C1451.979614,141.829361 1457.541626,148.611160 1463.574585,155.928986 
z"
            />
            <path
                fill="#1C65AD"
                opacity="1.000000"
                stroke="none"
                d=" M993.939209,213.682037 
	C993.520264,213.726593 993.101379,213.771133 992.233032,213.384735 
	C990.221741,212.650940 988.662659,212.104370 987.097473,212.086334 
	C979.289978,211.996292 971.468018,211.774597 963.677307,212.147110 
	C959.046204,212.368546 957.827148,210.880966 957.853271,206.309219 
	C958.041992,173.248688 957.989685,140.186462 957.875427,107.125244 
	C957.868164,105.024292 956.702454,102.927338 956.075623,100.828522 
	C943.910156,112.412941 931.727417,123.979263 919.583008,135.585648 
	C895.392212,158.704788 871.222412,181.845947 846.501221,205.497284 
	C809.975830,170.517197 773.760803,135.834351 736.582642,100.229088 
	C736.582642,138.773499 736.582642,176.103516 736.582642,213.639221 
	C722.308167,213.639221 708.714111,213.639221 694.626343,213.639221 
	C694.626343,162.184998 694.626343,110.947723 694.626343,59.087547 
	C695.945251,59.012684 697.521973,58.846764 699.098816,58.845356 
	C717.425354,58.829006 735.753845,58.709034 754.076294,58.983463 
	C756.701782,59.022785 759.935852,60.345623 761.812012,62.177002 
	C787.677002,87.424255 813.348450,112.869797 839.084229,138.249603 
	C841.448059,140.580704 843.927917,142.794067 846.623657,145.314316 
	C875.244873,117.184174 903.721680,89.196022 932.757935,60.980286 
	C933.505920,60.445076 933.694397,60.137440 933.882874,59.829800 
	C933.882874,59.829792 933.965759,59.971954 934.386108,59.951988 
	C956.097717,59.978096 977.388977,60.024170 998.680176,60.070244 
	C998.739136,82.893974 998.829163,105.717682 998.849060,128.541443 
	C998.872986,156.034363 998.837219,183.527328 998.402344,211.496338 
	C996.631287,212.542282 995.285217,213.112152 993.939209,213.682037 
z"
            />
            <path
                fill="#1C65AC"
                opacity="1.000000"
                stroke="none"
                d=" M1248.956909,119.023056 
	C1256.062866,119.618156 1263.254150,119.715111 1270.256714,120.914139 
	C1283.979980,123.263947 1293.153687,131.116898 1296.450806,144.975616 
	C1299.904541,159.493179 1299.927734,174.002014 1295.593628,188.373535 
	C1291.368408,202.383972 1280.794067,209.111130 1267.272705,210.930756 
	C1252.961792,212.856644 1238.480225,214.186462 1224.050781,214.426788 
	C1189.912231,214.995407 1155.760498,214.764786 1121.614014,214.854202 
	C1117.652954,214.864578 1113.691895,214.855606 1109.442627,214.855606 
	C1109.442627,203.271301 1109.442627,192.523026 1109.442627,181.378830 
	C1110.621704,181.304321 1111.875977,181.147644 1113.128906,181.157257 
	C1140.939331,181.370346 1168.750610,181.847321 1196.559326,181.728043 
	C1210.849121,181.666779 1225.174194,181.003845 1239.408325,179.756470 
	C1249.906372,178.836502 1254.023804,171.763550 1251.004639,162.213303 
	C1249.133667,156.295120 1244.419922,154.471634 1239.066162,154.090118 
	C1230.604980,153.487228 1222.113281,153.192291 1213.629761,153.046097 
	C1190.653320,152.650162 1167.673462,152.454819 1144.697510,152.040436 
	C1140.894165,151.971832 1137.063477,151.414062 1133.313843,150.715637 
	C1118.192261,147.899094 1108.997192,139.357239 1106.979370,124.083199 
	C1105.600586,113.644516 1105.271484,102.800247 1106.427124,92.353462 
	C1108.377930,74.717255 1117.330444,65.186325 1135.304443,62.167675 
	C1150.322510,59.645447 1165.683594,58.332672 1180.917847,58.095955 
	C1217.370239,57.529556 1253.837402,57.913006 1290.661377,57.913006 
	C1290.661377,68.906532 1290.661377,79.781807 1290.661377,91.134056 
	C1278.271362,91.134056 1265.991943,91.043983 1253.714478,91.152222 
	C1225.406982,91.401787 1197.100220,91.737152 1168.793701,92.081139 
	C1167.144165,92.101181 1165.492432,92.465668 1163.853516,92.740349 
	C1153.414307,94.489967 1150.105225,99.762619 1152.995605,110.851303 
	C1154.161865,112.629227 1155.265381,113.660995 1156.368896,114.692764 
	C1156.368896,114.692757 1156.212891,114.750069 1156.307739,115.014526 
	C1157.263428,115.514214 1158.124268,115.749443 1158.985107,115.984680 
	C1158.985107,115.984680 1159.004150,115.987503 1159.192871,116.261475 
	C1160.914307,116.756462 1162.446899,116.977486 1163.979492,117.198502 
	C1164.830444,117.249611 1165.681274,117.300720 1167.214600,117.695221 
	C1169.675049,118.356438 1171.452026,118.943054 1173.231445,118.950523 
	C1191.060059,119.025352 1208.888916,119.023438 1226.717651,118.976418 
	C1228.147095,118.972649 1229.575195,118.473183 1231.003906,118.204079 
	C1231.466187,118.194908 1231.928467,118.185738 1233.027588,118.477509 
	C1236.078491,118.686081 1238.492798,118.593704 1240.906982,118.501328 
	C1241.686646,118.504311 1242.466309,118.507301 1243.762939,118.916077 
	C1245.838867,119.222260 1247.397827,119.122658 1248.956909,119.023056 
z"
            />
            <path
                fill="#1C65AC"
                opacity="1.000000"
                stroke="none"
                d=" M147.952896,118.419144 
	C148.419693,118.431885 148.886505,118.444626 149.861435,118.919952 
	C155.239777,119.320419 160.109970,119.258293 164.980179,119.196167 
	C170.328156,119.962601 175.770416,120.331436 181.005798,121.572784 
	C194.676666,124.814240 202.162918,134.151520 204.806717,147.512558 
	C207.119431,159.200348 207.166321,170.998260 204.850830,182.716248 
	C201.377121,200.295547 190.750626,209.904892 169.926727,211.769485 
	C148.575394,213.681320 127.089592,214.465240 105.646042,214.875870 
	C80.500877,215.357361 55.337814,214.902679 30.182505,214.856934 
	C25.891796,214.849136 21.601063,214.855835 17.080664,214.855835 
	C17.080664,203.263214 17.080664,192.485458 17.080664,181.346832 
	C18.334351,181.277359 19.603737,181.141922 20.872633,181.146362 
	C44.360104,181.228668 67.849174,181.539810 91.334404,181.355286 
	C109.783134,181.210342 128.250671,180.868561 146.663818,179.811935 
	C156.821167,179.229065 160.411682,173.947983 159.132294,164.568771 
	C158.426208,159.392380 155.840088,155.526810 150.570923,154.957199 
	C141.335617,153.958832 132.034058,153.247253 122.749374,153.099319 
	C103.095650,152.786179 83.429611,153.161926 63.779568,152.742249 
	C55.506287,152.565552 47.137463,151.701950 39.030636,150.066925 
	C25.945042,147.427734 17.564571,139.379486 14.768839,126.005135 
	C12.145205,113.454056 12.172525,100.898232 14.648162,88.341888 
	C17.729557,72.713165 28.230722,64.354996 43.048393,62.147301 
	C58.621937,59.826984 74.433418,58.299213 90.168343,58.030533 
	C125.128021,57.433578 160.104828,57.837734 195.074661,57.847626 
	C196.053116,57.847904 197.031525,58.005177 198.289642,58.113270 
	C198.289642,68.995834 198.289642,79.752823 198.289642,91.161926 
	C196.369476,91.161926 194.604691,91.161911 192.839890,91.161934 
	C161.354523,91.162369 129.868500,91.049652 98.384232,91.235001 
	C89.417603,91.287781 80.420723,91.875328 71.502632,92.826363 
	C63.365810,93.694084 60.507362,96.550079 59.852200,102.743385 
	C59.085411,109.991867 61.227894,113.375969 68.943039,116.959244 
	C70.223007,117.373016 71.099083,117.292198 71.975166,117.211380 
	C73.120522,117.298065 74.265877,117.384758 76.076340,117.847763 
	C78.009026,118.481407 79.275970,118.959221 80.544289,118.962906 
	C98.764061,119.015823 116.984055,119.019913 135.203857,118.976669 
	C136.465164,118.973679 137.725372,118.504066 138.986084,118.251450 
	C141.046539,118.230431 143.107010,118.209412 145.753510,118.443863 
	C146.877335,118.605934 147.415115,118.512543 147.952896,118.419144 
z"
            />
            <path
                fill="#1D65AC"
                opacity="1.000000"
                stroke="none"
                d="
M321.000000,213.857758 
	C306.701477,213.288681 292.822113,213.468979 279.121643,211.990921 
	C252.491241,209.117905 239.466736,196.526535 236.374023,170.208832 
	C233.472946,145.521896 233.293625,120.668922 237.040115,96.001991 
	C240.158188,75.472588 255.146179,61.793270 275.857544,59.973515 
	C282.981750,59.347569 290.147583,58.902718 297.295563,58.886986 
	C341.792542,58.789055 386.289856,58.836441 430.787048,58.835651 
	C432.260651,58.835625 433.734253,58.835648 435.540894,58.835648 
	C435.540894,71.149467 435.540894,83.074745 435.540894,95.862206 
	C433.691559,95.862206 431.780792,95.859894 429.869995,95.862556 
	C390.207306,95.917747 350.544556,95.960045 310.881958,96.056198 
	C307.897186,96.063431 304.882568,96.253777 301.934509,96.704178 
	C290.471405,98.455521 284.675873,102.934212 283.667847,114.406013 
	C282.481171,127.911041 282.299469,141.713821 283.694489,155.179001 
	C285.172760,169.447861 292.867920,174.844254 308.335999,174.933167 
	C342.830841,175.131470 377.327118,175.088577 411.822784,175.138824 
	C419.619690,175.150177 427.416626,175.140488 435.533569,175.140488 
	C435.533569,188.154922 435.533569,200.707764 435.533569,213.857773 
	C397.386261,213.857773 359.443146,213.857773 321.000000,213.857758 
z"
            />
            <path
                fill="#1D65AC"
                opacity="1.000000"
                stroke="none"
                d="
M1324.690918,100.678528 
	C1326.844116,77.328094 1342.183838,61.796749 1364.895996,59.908108 
	C1372.515625,59.274502 1380.179443,58.900860 1387.824097,58.883492 
	C1430.307129,58.786957 1472.790405,58.836632 1515.273682,58.836269 
	C1517.093140,58.836254 1518.912598,58.836266 1521.153809,58.836266 
	C1521.153809,71.156509 1521.153809,83.179985 1521.153809,95.861801 
	C1519.116821,95.861801 1517.184937,95.859367 1515.253174,95.862167 
	C1476.603516,95.918091 1437.953491,95.903839 1399.304199,96.059258 
	C1378.247681,96.143929 1371.370117,102.803520 1370.949951,123.744186 
	C1370.750244,133.702682 1370.521484,143.787445 1371.803467,153.619766 
	C1373.839966,169.239334 1380.715454,174.759598 1396.620972,174.933929 
	C1417.775757,175.165787 1438.934082,175.110184 1460.090820,175.139862 
	C1479.250000,175.166733 1498.409058,175.142303 1517.568237,175.140533 
	C1519.202148,175.140381 1520.836060,175.140518 1522.817505,175.140518 
	C1522.817505,188.097672 1522.817505,200.656143 1522.817505,213.858795 
	C1521.156250,213.858795 1519.550293,213.858841 1517.944214,213.858810 
	C1476.793823,213.858109 1435.641113,214.092102 1394.494263,213.711411 
	C1382.398438,213.599503 1370.118164,212.771576 1358.277100,210.457413 
	C1338.888184,206.668152 1328.182617,193.445587 1325.369995,174.328812 
	C1321.791992,150.011276 1322.049438,125.548836 1324.690918,100.678528 
z"
            />
            <path
                fill="#0D65AE"
                opacity="1.000000"
                stroke="none"
                d="
M612.778259,72.631119 
	C627.853455,87.688461 642.967468,102.707390 657.937988,117.868080 
	C659.294373,119.241722 660.467773,121.523979 660.477539,123.390816 
	C660.635437,153.519485 660.592346,183.649216 660.581421,213.778717 
	C660.581177,214.400513 660.421082,215.022247 660.176453,216.801437 
	C627.851929,184.472336 596.167542,152.783447 564.233643,120.886673 
	C563.984253,120.678818 563.969910,120.535759 564.227173,120.278183 
	C578.823364,105.708008 593.149292,91.382370 607.519043,77.100784 
	C609.145874,75.483902 611.019226,74.115021 612.778259,72.631119 
z"
            />
            <path
                fill="#2AC0F7"
                opacity="1.000000"
                stroke="none"
                d="
M563.969910,120.535767 
	C563.969910,120.535759 563.984253,120.678818 563.925781,120.721756 
	C532.254089,152.441956 500.640900,184.119217 468.161011,216.664948 
	C467.996521,213.863480 467.841766,212.452728 467.841492,211.041946 
	C467.835724,182.044083 467.803497,153.046005 467.947876,124.048820 
	C467.958313,121.953476 468.687744,119.281670 470.083801,117.862480 
	C485.041351,102.656891 500.200653,87.649742 515.781860,72.655899 
	C532.162720,88.660988 548.066284,104.598381 563.969910,120.535767 
z"
            />
            <path
                fill="#A1CF4C"
                opacity="1.000000"
                stroke="none"
                d="
M564.227173,120.278183 
	C548.066284,104.598381 532.162720,88.660988 516.040161,72.482590 
	C522.055115,65.821060 528.244690,59.356804 534.533264,52.990192 
	C544.096985,43.307701 553.729309,33.692963 563.950684,23.428755 
	C580.292480,39.808086 596.407776,55.960407 612.650696,72.371925 
	C611.019226,74.115021 609.145874,75.483902 607.519043,77.100784 
	C593.149292,91.382370 578.823364,105.708008 564.227173,120.278183 
z"
            />
            <path
                fill="#2167AB"
                opacity="1.000000"
                stroke="none"
                d="
M1463.339111,155.660980 
	C1457.541626,148.611160 1451.979614,141.829361 1445.664795,134.129623 
	C1452.789551,134.129623 1458.839478,134.129852 1464.889282,134.129593 
	C1474.707764,134.129150 1484.527466,134.050781 1494.343506,134.204575 
	C1495.968872,134.230057 1498.154297,134.785751 1499.104370,135.916809 
	C1507.116089,145.454483 1514.900635,155.182907 1523.228516,165.438980 
	C1521.389648,165.625763 1520.192993,165.850830 1518.995850,165.853043 
	C1505.682617,165.877655 1492.359741,165.572372 1479.061035,166.010590 
	C1473.635986,166.189346 1469.823120,164.760071 1467.020386,160.196442 
	C1466.071533,158.651306 1464.734375,157.344635 1463.339111,155.660980 
z"
            />
            <path
                fill="#1A64AD"
                opacity="1.000000"
                stroke="none"
                d="
M1038.980103,175.946503 
	C1039.467285,175.697449 1039.954590,175.448395 1041.058594,175.442657 
	C1051.915649,176.252457 1062.155884,176.818924 1072.396240,177.385391 
	C1072.396240,177.385391 1072.504883,177.501450 1072.387451,177.986816 
	C1072.306641,189.754272 1072.343140,201.036346 1072.379639,212.318420 
	C1072.379761,212.318420 1072.275879,212.286560 1071.887573,212.231461 
	C1059.542603,214.728165 1047.623535,216.075577 1035.902588,211.066299 
	C1035.902588,211.066299 1035.990479,211.008316 1035.984131,210.574921 
	C1036.158325,199.309753 1036.338867,188.477997 1036.519409,177.646225 
	C1036.519409,177.646225 1036.602905,177.640350 1036.923096,177.648331 
	C1037.822144,177.086365 1038.401123,176.516434 1038.980103,175.946503 
z"
            />
            <path
                fill="#4378AD"
                opacity="1.000000"
                stroke="none"
                d="
M956.143066,101.305023 
	C956.702454,102.927338 957.868164,105.024292 957.875427,107.125244 
	C957.989685,140.186462 958.041992,173.248688 957.853271,206.309219 
	C957.827148,210.880966 959.046204,212.368546 963.677307,212.147110 
	C971.468018,211.774597 979.289978,211.996292 987.097473,212.086334 
	C988.662659,212.104370 990.221741,212.650940 991.922485,213.317230 
	C980.447327,213.680634 968.833252,213.680634 956.210571,213.680634 
	C956.210571,176.376648 956.210571,139.079071 956.143066,101.305023 
z"
            />
            <path
                fill="#4378AD"
                opacity="1.000000"
                stroke="none"
                d="
M998.656738,59.691917 
	C977.388977,60.024170 956.097717,59.978096 934.327332,59.894394 
	C935.018616,59.455509 936.187805,58.708447 937.359558,58.704521 
	C957.004211,58.638775 976.649231,58.658443 996.294067,58.699257 
	C997.074219,58.700874 997.853577,59.099789 998.656738,59.691917 
z"
            />
            <path
                fill="#3A6998"
                opacity="1.000000"
                stroke="none"
                d="
M138.531311,118.130348 
	C137.725372,118.504066 136.465164,118.973679 135.203857,118.976669 
	C116.984055,119.019913 98.764061,119.015823 80.544289,118.962906 
	C79.275970,118.959221 78.009026,118.481407 76.484116,117.990555 
	C96.843376,117.841110 117.459953,117.925179 138.531311,118.130348 
z"
            />
            <path
                fill="#3A6895"
                opacity="1.000000"
                stroke="none"
                d="
M1230.544800,118.096016 
	C1229.575195,118.473183 1228.147095,118.972649 1226.717651,118.976418 
	C1208.888916,119.023438 1191.060059,119.025352 1173.231445,118.950523 
	C1171.452026,118.943054 1169.675049,118.356438 1167.599121,117.883270 
	C1188.229614,117.814598 1209.157593,117.901276 1230.544800,118.096016 
z"
            />
            <path
                fill="#4176A8"
                opacity="1.000000"
                stroke="none"
                d="
M1072.677734,212.110809 
	C1072.343140,201.036346 1072.306641,189.754272 1072.439941,178.046509 
	C1072.928711,177.996201 1073.473389,178.338806 1073.533325,178.751785 
	C1075.140991,189.829224 1076.070068,200.902740 1072.677734,212.110809 
z"
            />
            <path
                fill="#4176A8"
                opacity="1.000000"
                stroke="none"
                d="
M1036.239746,177.874298 
	C1036.338867,188.477997 1036.158325,199.309753 1035.951904,210.616547 
	C1035.421021,209.750107 1034.504028,208.416443 1034.478760,207.065964 
	C1034.325928,198.951172 1034.294922,190.831116 1034.433594,182.716431 
	C1034.459961,181.170166 1035.427002,179.639984 1036.239746,177.874298 
z"
            />
            <path
                fill="#4176A8"
                opacity="1.000000"
                stroke="none"
                d="
M1035.979736,211.419586 
	C1047.623535,216.075577 1059.542603,214.728165 1071.911133,212.280426 
	C1069.962891,213.283524 1067.635132,214.840179 1065.236816,214.957840 
	C1058.144287,215.305817 1051.005249,215.381744 1043.924072,214.935165 
	C1041.245728,214.766251 1038.676025,212.877625 1035.979736,211.419586 
z"
            />
            <path
                fill="#4176A8"
                opacity="1.000000"
                stroke="none"
                d="
M1072.182373,177.098541 
	C1062.155884,176.818924 1051.915649,176.252457 1041.341553,175.446411 
	C1049.656860,175.109924 1058.307007,174.911057 1066.953857,175.004364 
	C1068.631592,175.022461 1070.297241,176.179825 1072.182373,177.098541 
z"
            />
            <path
                fill="#3A6998"
                opacity="1.000000"
                stroke="none"
                d="
M164.533386,119.092911 
	C160.109970,119.258293 155.239777,119.320419 150.179214,119.074646 
	C154.688095,118.841042 159.387344,118.915352 164.533386,119.092911 
z"
            />
            <path
                fill="#4378AD"
                opacity="1.000000"
                stroke="none"
                d="
M994.326599,213.790482 
	C995.285217,213.112152 996.631287,212.542282 998.336060,211.956573 
	C997.367798,212.593475 996.040894,213.246216 994.326599,213.790482 
z"
            />
            <path
                fill="#3A6895"
                opacity="1.000000"
                stroke="none"
                d="
M1240.495117,118.346909 
	C1238.492798,118.593704 1236.078491,118.686081 1233.364014,118.566177 
	C1235.403442,118.300102 1237.743286,118.246292 1240.495117,118.346909 
z"
            />
            <path
                fill="#3A6895"
                opacity="1.000000"
                stroke="none"
                d="
M1248.605469,118.928223 
	C1247.397827,119.122658 1245.838867,119.222260 1244.130737,119.046043 
	C1245.405762,118.791283 1246.829956,118.812340 1248.605469,118.928223 
z"
            />
            <path
                fill="#3A6895"
                opacity="1.000000"
                stroke="none"
                d="
M1163.625977,117.091476 
	C1162.446899,116.977486 1160.914307,116.756462 1159.201660,116.265244 
	C1160.438599,116.324837 1161.855469,116.654640 1163.625977,117.091476 
z"
            />
            <path
                fill="#3A6895"
                opacity="1.000000"
                stroke="none"
                d="
M1156.233154,114.405937 
	C1155.265381,113.660995 1154.161865,112.629227 1153.056152,111.249329 
	C1154.068481,111.973839 1155.083008,113.046471 1156.233154,114.405937 
z"
            />
            <path
                fill="#3A6998"
                opacity="1.000000"
                stroke="none"
                d="
M71.618553,117.104904 
	C71.099083,117.292198 70.223007,117.373016 69.190712,117.201019 
	C69.776978,116.964943 70.519463,116.981689 71.618553,117.104904 
z"
            />
            <path
                fill="#3A6895"
                opacity="1.000000"
                stroke="none"
                d="
M1158.750366,115.781876 
	C1158.124268,115.749443 1157.263428,115.514214 1156.330200,114.934509 
	C1157.010376,114.919716 1157.763062,115.249390 1158.750366,115.781876 
z"
            />
            <path
                fill="#4176A8"
                opacity="1.000000"
                stroke="none"
                d="
M1038.675781,175.964447 
	C1038.401123,176.516434 1037.822144,177.086365 1036.924561,177.606537 
	C1037.194580,177.031967 1037.782959,176.507172 1038.675781,175.964447 
z"
            />
            <path
                fill="#3A6998"
                opacity="1.000000"
                stroke="none"
                d="
M147.644455,118.294441 
	C147.415115,118.512543 146.877335,118.605934 146.187302,118.528305 
	C146.468704,118.294769 146.902359,118.232254 147.644455,118.294441 
z"
            />
            <path
                fill="#4378AD"
                opacity="1.000000"
                stroke="none"
                d="
M933.621460,59.914448 
	C933.694397,60.137440 933.505920,60.445076 933.048035,60.827065 
	C932.972412,60.600647 933.166199,60.299870 933.621460,59.914448 
z"
            />
        </svg>
    );
};

export default Logo;
